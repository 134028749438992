import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import styles from './blog.module.css'
import '../components/base.css'

class Terms extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    return (
      <div
        style={{ background: '#fff', maxWidth: '100vw', overflow: 'hidden' }}
      >
        <Helmet title={siteTitle} />
        <div className={styles.hero}>
          <h3>
            Walk<span style={{ color: '#fff' }}>Up</span> Privacy Policy
          </h3>
          <p />
        </div>
        <div class="legal-container css-9zkfy e1gmw18q0">
          <h3>Introduction</h3>
          <p>
            Welcome to the WalkUp Ltd’s privacy policy. WalkUp Ltd (brand name:
            WalkUp) respects your privacy and is committed to protecting your
            personal data. This privacy policy will inform you as to how we look
            after your personal data when you visit our website,
            https://walkup.co (Website) (regardless of where you visit it from)
            or use the WalkUp mobile application (App), or and tell you about
            your privacy rights and how the law protects you. 1. Important
            information and who we are
          </p>
          <h3>Purpose of this privacy policy</h3>
          <p>
            This privacy policy aims to give you information on how WalkUp Ltd
            collects and processes your personal data through your use of our
            Website and the App, including any data you may provide through the
            Website or App when you sign up to our newsletter, use our services
            or take part in a competition. It is important that you read this
            privacy policy together with any other privacy policy or fair
            processing policy we may provide on specific occasions when we are
            collecting or processing personal data about you so that you are
            fully aware of how and why we are using your data. This privacy
            policy supplements other notices and privacy policies and is not
            intended to override them.
          </p>
          <h3>Controller</h3>
          <p>
            WalkUp Ltd is the controller and responsible for your personal data
            (collectively referred to as "WalkUp", "we", "us" or "our" in this
            privacy policy). We have appointed a data privacy manager who is
            responsible for overseeing questions in relation to this privacy
            policy. If you have any questions about this privacy policy,
            including any requests to exercise your legal rights, please contact
            the data privacy manager using the details set out below.
          </p>
          <h3>Contact details</h3>
          <br />
          <div class="css-j7qwjs e1gmw18q5">
            <p>
              <b>Amos Teshuva</b>, <b>hello@walkup.co</b>
            </p>
            <b>1 Manchester Square, W1U 3AB, London</b>{' '}
          </div>
          <br />
          <p>
            You have the right to make a complaint at any time to the
            Information Commissioner's Office (ICO), the UK supervisory
            authority for data protection issues (www.ico.org.uk). We would,
            however, appreciate the chance to deal with your concerns before you
            approach the ICO so please contact us in the first instance.
          </p>
          <h3>Third-party links</h3>
          <p>
            The Website and App may include links to third-party websites,
            plug-ins and applications. Clicking on those links or enabling those
            connections may allow third parties to collect or share data about
            you. We do not control these third-party websites and are not
            responsible for their privacy statements. When you leave our website
            and App, we encourage you to read the privacy policy of every
            website or application you visit or use.
          </p>
          <h3>2. The data we collect about you</h3>
          <p>
            Personal data, or personal information, means any information about
            an individual from which that person can be identified. It does not
            include data where the identity has been removed (anonymous data).
            We may collect, use, store and transfer different kinds of personal
            data about you which we have grouped together as follows:
          </p>
          <p>
            <b>Identity Data</b> includes first name, maiden name, last name,
            username or similar identifier, marital status, title, date of birth
            and gender.
          </p>
          <p>
            <b>Contact Data</b> includes billing address, delivery address,
            email address, telephone numbers and bank account details.
          </p>
          <p>
            <b>Technical Data</b> includes internet protocol (IP) address, your
            login data, browser type and version, time zone setting and
            location, browser plug-in types and versions, operating system and
            platform, the type of mobile device you use, a unique device
            identifier (for example, your device's IMEI number, the MAC address
            of the device's wireless network interface, or the mobile phone
            number used by the device), mobile network information, your mobile
            operating system, the type of mobile browser you use, and other
            technology on the devices you use to access this website.
          </p>
          <p>
            <b>Profile Data</b> includes your username and password, purchases
            or orders made by you, your interests, preferences, feedback and
            survey responses. We may also collect information store on your
            device used to access the App such as contact information, friends
            lists, login information, photos, videos or other digital content
            and check ins.
          </p>
          <p>
            <b>Usage Data</b> includes information about how you use our
            website, app, products and services including but not limited to
            traffic data, weblogs and other communication data.
          </p>
          <p>
            <b>Location Information</b> where we use GPS technology to determine
            your current location. Some of our location-enabled services require
            your personal data for the feature to work.
          </p>
          <p>
            We also collect, use and share <b>Aggregated Data</b> such as
            statistical or demographic data for any purpose. Aggregated Data
            could be derived from your personal data but is not considered
            personal data in law as this data will <b>not</b> directly or
            indirectly reveal your identity. For example, we may aggregate your
            Usage Data to calculate the percentage of users accessing a specific
            website feature. However, if we combine or connect Aggregated Data
            with your personal data so that it can directly or indirectly
            identify you, we treat the combined data as personal data which will
            be used in accordance with this privacy policy.
          </p>
          <p>
            We do not collect any <b>Special Categories of Personal Data</b>{' '}
            about you (this includes details about your race or ethnicity,
            religious or philosophical beliefs, sex life, sexual orientation,
            political opinions, trade union membership, information about your
            health, and genetic and biometric data). Nor do we collect any
            information about criminal convictions and offences.
          </p>
          <p>
            It is important that the personal data we hold about you is accurate
            and current. Please keep us informed if your personal data changes
            during your relationship with us.
          </p>
          <h3>If you fail to provide personal data</h3>
          <p>
            Where we need to collect personal data by law, or under the terms of
            a contract we have with you, and you fail to provide that data when
            requested, we may not be able to perform the contract we have or are
            trying to enter into with you (for example, to provide you with
            goods or services). In this case, we may have to cancel the supply
            of our services to you but we will notify you if this is the case at
            the time.
          </p>
          <h3>3. How is your personal data collected?</h3>We use different
          methods to collect data from and about you including through:
          <p>
            • <b>Direct interactions.</b> You may give us your Identity and
            Contact Data when you or a restaurant on your behalf fills forms or
            by corresponding with us by post, phone, email, chat or otherwise.
            This includes personal data you provide when you:
          </p>
          <div class="css-j7qwjs e1gmw18q5">
            <p>• apply for our products or services;</p>
            <p>• create an account on our Website or App;</p>
            <p>• download our App;</p>
            <p>• make an in-App purchase;</p>
            <p>• share data via the App’s social media functions;</p>
            <p>• subscribe to our service or publications;</p>
            <p>• request marketing to be sent to you;</p>
            <p>• enter a competition, promotion or survey; or</p>
            <p>• give us feedback or contact us.</p>
          </div>
          <p>
            • <b>Automated technologies or interactions.</b> As you interact
            with our Website and the App, we will automatically collect
            Technical Data, Profile Data, Usage Data and Location Information.
            We collect this personal data by using cookies and other similar
            technologies.
          </p>
          <p>
            • <b>Third parties or publicly available sources.</b> We will
            receive personal data about you from various third parties as set
            out below:
          </p>
          <p>
            • Technical Data from the following parties: (a) analytics providers
            such as Google; (b) advertising networks; and (c) search information
            providers. • Identity and Contact Data from data brokers.
          </p>
          <h3>4. How we use your personal data</h3>
          <p>
            We will only use your personal data when the law allows us to. Most
            commonly, we will use your personal data in the following
            circumstances:
          </p>
          <p>
            • Where we need to perform the contract we are about to enter into
            or have entered into with you.
          </p>
          <p>
            • Where it is necessary for our legitimate interests (or those of a
            third party) and your interests and fundamental rights do not
            override those interests.
          </p>
          <p>• Where we need to comply with a legal obligation.</p>
          <p>
            Generally, we do not rely on consent as a legal basis for processing
            your personal data although we will get your consent before sending
            third party direct marketing communications to you via email or text
            message. You have the right to withdraw consent to marketing at any
            time by contacting us.
          </p>
          <h3>Purposes for which we will use your personal data</h3>
          <p>
            We have set out below, in a table format, a description of all the
            ways we plan to use your personal data, and which of the legal bases
            we rely on to do so. We have also identified what our legitimate
            interests are where appropriate.
          </p>
          <p>
            Note that we may process your personal data for more than one lawful
            ground depending on the specific purpose for which we are using your
            data. Please contact us if you need details about the specific legal
            ground we are relying on to process your personal data where more
            than one ground has been set out in the table below.
          </p>
          <table class="table css-vmn85o e1gmw18q1">
            <tbody>
              <tr>
                <td class="css-ygw6nu e1gmw18q4">
                  <b>Purpose/Activity</b>
                </td>
                <td class="css-ygw6nu e1gmw18q4">
                  <b>
                    Lawful basis for processing including basis of legitimate
                    interest
                  </b>
                </td>
              </tr>
              <tr>
                <td class="css-ygw6nu e1gmw18q4">
                  To register you as a new customer
                </td>
                <td class="css-ygw6nu e1gmw18q4">
                  Performance of a contract with you
                </td>
              </tr>
              <tr>
                <td class="css-ygw6nu e1gmw18q4">
                  To process and deliver services to you
                </td>
                <td class="css-ygw6nu e1gmw18q4">
                  Performance of a contract with you
                </td>
              </tr>
              <tr>
                <td class="css-ygw6nu e1gmw18q4">
                  <p>To manage our relationship with you which will include:</p>
                  <p>
                    (a) Notifying you about changes to our terms or privacy
                    policy
                  </p>
                  (b) Asking you to leave a review or take a survey
                </td>
                <td class="css-ygw6nu e1gmw18q4">
                  <p>(a) Performance of a contract with you</p>
                  <p>(b) Necessary to comply with a legal obligation</p>
                  <p>
                    (c) Necessary for our legitimate interests (to keep our
                    records updated and to study how customers use our
                    products/services)
                  </p>
                </td>
              </tr>
              <tr>
                <td class="css-ygw6nu e1gmw18q4">
                  <p>
                    To enable you to partake in a prize draw, competition,
                    referral programme or complete a survey
                  </p>
                </td>
                <td class="css-ygw6nu e1gmw18q4">
                  <p>(a) Performance of a contract with you</p>
                  <p>
                    (b) Necessary for our legitimate interests (to study how
                    customers use our products/services, to develop them and
                    grow our business)
                  </p>
                </td>
              </tr>
              <tr>
                <td class="css-ygw6nu e1gmw18q4">
                  To administer and protect our business and our Website and App
                  (including troubleshooting, data analysis, testing, system
                  maintenance, support, reporting and hosting of data)
                </td>
                <td class="css-ygw6nu e1gmw18q4">
                  <p>
                    (a) Necessary for our legitimate interests (for running our
                    business, provision of administration and IT services,
                    network security, to prevent fraud and in the context of a
                    business reorganisation or group restructuring exercise)
                  </p>
                  (b) Necessary to comply with a legal obligation
                </td>
              </tr>
              <tr>
                <td class="css-ygw6nu e1gmw18q4">
                  To deliver relevant Website and App content and advertisements
                  to you and measure or understand the effectiveness of the
                  advertising we serve to you
                </td>
                <td class="css-ygw6nu e1gmw18q4">
                  Necessary for our legitimate interests (to study how customers
                  use our products/services, to develop them, to grow our
                  business and to inform our marketing strategy)
                </td>
              </tr>
              <tr>
                <td class="css-ygw6nu e1gmw18q4">
                  To use data analytics to improve our Website, App
                  products/services, marketing, customer relationships and
                  experiences
                </td>
                <td class="css-ygw6nu e1gmw18q4">
                  Necessary for our legitimate interests (to define types of
                  customers for our products and services, to keep our website
                  updated and relevant, to develop our business and to inform
                  our marketing strategy)
                </td>
              </tr>
              <tr>
                <td class="css-ygw6nu e1gmw18q4">
                  To make suggestions and recommendations to you about goods or
                  services that may be of interest to you
                </td>
                <td class="css-ygw6nu e1gmw18q4">
                  Necessary for our legitimate interests (to develop our
                  products/services and grow our business)
                </td>
              </tr>
              <tr>
                <td class="css-ygw6nu e1gmw18q4">
                  To make available your basic contact details to our partners
                  (such as restaurants and retail outlets) for the purposes of
                  contact tracing under the NHS Test and Trace service or
                  similar system in relation to Covid-19 (or any other pandemic)
                </td>
                <td class="css-ygw6nu e1gmw18q4">
                  <p>
                    (a) Necessary for the legitimate interests of our partners
                    (to protect their businesses and comply with government
                    guidance and legislation)
                  </p>
                  <p>
                    (b) Necessary to protect your vital interests and those of
                    other natural persons
                  </p>
                  <p>(c) Necessary to comply with a legal obligation</p>
                  <p>
                    (d) Necessary for a task carried out in the public interest
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <h3>Marketing</h3>
          <p>
            We strive to provide you with choices regarding certain personal
            data uses, particularly around marketing and advertising.
          </p>
          <p>
            We may use your Identity, Contact, Technical, Usage and Profile Data
            to form a view on what we think you may want or need, or what may be
            of interest to you. This is how we decide which products, services
            and offers may be relevant for you (we call this marketing).
          </p>
          <p>
            You will receive marketing communications from us if you have
            requested information from us or requested services from us and you
            have not opted out of receiving that marketing.
          </p>
          <p>
            We will get your express opt-in consent before we share your
            personal data with any third party for marketing purposes.
          </p>
          <p>
            You can ask us or third parties to stop sending you marketing
            messages at any time by logging into the Website or App and checking
            or unchecking relevant boxes to adjust your marketing preferences or
            by contacting us at any time.
          </p>
          <p>
            Where you opt out of receiving these marketing messages, this will
            not apply to personal data provided to us as a result of
            product/service experience or other transactions.
          </p>
          <p>
            You can set your browser to refuse all or some browser cookies, or
            to alert you when websites or applications set or access cookies. If
            you disable or refuse cookies, please note that some parts of our
            Website and App may become inaccessible or not function properly.
          </p>
          <h3>Change of purpose</h3>
          <p>
            We will only use your personal data for the purposes for which we
            collected it, unless we reasonably consider that we need to use it
            for another reason and that reason is compatible with the original
            purpose.
          </p>
          <p>
            If we need to use your personal data for an unrelated purpose, we
            will notify you and we will explain the legal basis which allows us
            to do so.
          </p>
          <p>
            Please note that we may process your personal data without your
            knowledge or consent, in compliance with the above rules, where this
            is required or permitted by law.
          </p>
          <h3>5. Disclosures of your personal data</h3>
          <p>
            We may share your personal data with the parties set out below for
            the purposes set out in the table above.
          </p>
          <p>
            • Service providers acting as processors who provide IT and system
            administration services.
          </p>
          <p>
            • Service providers acting as processors who provide text message
            processing services to allow us to receive and process any text
            messages you send to us.
          </p>
          <p>
            • Professional advisers acting as processors or joint controllers
            including lawyers, bankers, auditors and insurers based in
            consultancy, banking, legal, insurance and accounting services.
          </p>
          <p>
            • Our partner (such as restaurants and retail outlets) acting as
            processors and controllers for the purposes of delivering the
            services you have requested, to analyse this data to improve their
            support and customer offering, and to comply with their legal
            obligations and duties in relation to contact tracing.
          </p>
          <p>
            • Third parties to whom we may choose to sell, transfer or merge
            parts of our business or our assets. Alternatively, we may seek to
            acquire other businesses or merge with them. If a change happens to
            our business, then the new owners may use your personal data in the
            same way as set out in this privacy policy.
          </p>
          <p>
            We require all third parties to respect the security of your
            personal data and to treat it in accordance with the law. We do not
            allow our third-party service providers to use your personal data
            for their own purposes and only permit them to process your personal
            data for specified purposes and in accordance with our instructions.
          </p>
          <h3>6. International transfers</h3>
          <p>
            Our data is hosted using the Google Cloud platform, which therefore
            involves the transfer of your data outside the European Economic
            Area because it has servers worldwide (EEA).
          </p>
          <p>
            Whenever we transfer your personal data out of the EEA, we ensure a
            similar degree of protection is afforded to it. In the case of
            Google, please see the following link which sets out the safeguards
            that are in place: https://cloud.google.com/terms.
          </p>
          <p>
            Please contact us if you want further information on the specific
            mechanism used by us when transferring your personal data out of the
            EEA.
          </p>
          <h3>7. Data security</h3>
          <p>
            We have put in place appropriate security measures to prevent your
            personal data from being accidentally lost, used or accessed in an
            unauthorised way, altered or disclosed. In addition, we limit access
            to your personal data to those employees, agents, contractors and
            other third parties who have a business need to know. They will only
            process your personal data on our instructions and they are subject
            to a duty of confidentiality.
          </p>
          <p>
            We have put in place procedures to deal with any suspected personal
            data breach and will notify you and any applicable regulator of a
            breach where we are legally required to do so.
          </p>
          <h3>8. Data retention</h3>
          <p>
            <b>How long will you use my personal data for? </b>We will only
            retain your personal data for as long as reasonably necessary to
            fulfil the purposes we collected it for, including for the purposes
            of satisfying any legal, regulatory, tax, accounting or reporting
            requirements. We may retain your personal data for a longer period
            in the event of a complaint or if we reasonably believe there is a
            prospect of litigation in respect to our relationship with you.We
            will only retain your personal data for as long as reasonably
            necessary to fulfil the purposes we collected it for, including for
            the purposes of satisfying any legal, regulatory, tax, accounting or
            reporting requirements. We may retain your personal data for a
            longer period in the event of a complaint or if we reasonably
            believe there is a prospect of litigation in respect to our
            relationship with you.
          </p>
          <p>
            To determine the appropriate retention period for personal data, we
            consider the amount, nature and sensitivity of the personal data,
            the potential risk of harm from unauthorised use or disclosure of
            your personal data, the purposes for which we process your personal
            data and whether we can achieve those purposes through other means,
            and the applicable legal, regulatory, tax, accounting or other
            requirements.
          </p>
          <p>
            By law we have to keep basic information about our customers
            (including Contact and Identity Data) for six years after they cease
            being customers for tax purposes.
          </p>
          <p>
            In some circumstances you can ask us to delete your data: see your
            legal rights below for further information.
          </p>
          <p>
            In some circumstances we will anonymise your personal data (so that
            it can no longer be associated with you) for research or statistical
            purposes, in which case we may use this information indefinitely
            without further notice to you.
          </p>
          <h3>9. Your legal rights</h3>
          <p>
            Under certain circumstances, you have rights under data protection
            laws in relation to your personal data.
          </p>
          <p>You have the right to:</p>
          <p>
            <b>Request access</b> to your personal data (commonly known as a
            "data subject access request"). This enables you to receive a copy
            of the personal data we hold about you and to check that we are
            lawfully processing it.
          </p>
          <p>
            <b>Request correction</b> of the personal data that we hold about
            you. This enables you to have any incomplete or inaccurate data we
            hold about you corrected, though we may need to verify the accuracy
            of the new data you provide to us.
          </p>
          <p>
            <b>Request erasure</b> of your personal data. This enables you to
            ask us to delete or remove personal data where there is no good
            reason for us continuing to process it. You also have the right to
            ask us to delete or remove your personal data where you have
            successfully exercised your right to object to processing (see
            below), where we may have processed your information unlawfully or
            where we are required to erase your personal data to comply with
            local law. Note, however, that we may not always be able to comply
            with your request of erasure for specific legal reasons which will
            be notified to you, if applicable, at the time of your request.
          </p>
          <p>
            <b>Object to processing</b> of your personal data where we are
            relying on a legitimate interest (or those of a third party) and
            there is something about your particular situation which makes you
            want to object to processing on this ground as you feel it impacts
            on your fundamental rights and freedoms. You also have the right to
            object where we are processing your personal data for direct
            marketing purposes. In some cases, we may demonstrate that we have
            compelling legitimate grounds to process your information which
            override your rights and freedoms.
          </p>
          <p>
            <b>Request restriction of processing</b> of your personal data. This
            enables you to ask us to suspend the processing of your personal
            data in the following scenarios:
          </p>
          <p>• If you want us to establish the data's accuracy.</p>
          <p>
            • Where our use of the data is unlawful but you do not want us to
            erase it.
          </p>
          <p>
            • Where you need us to hold the data even if we no longer require it
            as you need it to establish, exercise or defend legal claims.
          </p>
          <p>
            • You have objected to our use of your data but we need to verify
            whether we have overriding legitimate grounds to use it.
          </p>
          <p>
            <b>Request the transfer</b> of your personal data to you or to a
            third party. We will provide to you, or a third party you have
            chosen, your personal data in a structured, commonly used,
            machine-readable format. Note that this right only applies to
            automated information which you initially provided consent for us to
            use or where we used the information to perform a contract with you.
          </p>
          <p>
            <b>Withdraw consent at any time</b> where we are relying on consent
            to process your personal data. However, this will not affect the
            lawfulness of any processing carried out before you withdraw your
            consent. If you withdraw your consent, we may not be able to provide
            certain products or services to you. We will advise you if this is
            the case at the time you withdraw your consent. If you wish to
            exercise any of the rights set out above, please contact us.
          </p>
          <h3>No fee usually required</h3>
          <p>
            You will not have to pay a fee to access your personal data (or to
            exercise any of the other rights). However, we may charge a
            reasonable fee if your request is clearly unfounded, repetitive or
            excessive. Alternatively, we could refuse to comply with your
            request in these circumstances.
          </p>
          <h3>What we may need from you</h3>
          <p>
            We may need to request specific information from you to help us
            confirm your identity and ensure your right to access your personal
            data (or to exercise any of your other rights). This is a security
            measure to ensure that personal data is not disclosed to any person
            who has no right to receive it. We may also contact you to ask you
            for further information in relation to your request to speed up our
            response.
          </p>
          <h3>Time limit to respond</h3>
          <p>
            We try to respond to all legitimate requests within one month.
            Occasionally it could take us longer than a month if your request is
            particularly complex or you have made a number of requests. In this
            case, we will notify you and keep you updated.
          </p>
        </div>
      </div>
    )
  }
}

export default Terms

export const pageQuery = graphql`
  query PrivacyQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
